body {
  margin: 0;
  font-family: "Roboto", "Lexend", "Sora", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.map-marker-label {
  background: white;
  border-radius: 500px;
  padding: 0 4px;
  margin-bottom: 20px;
}

.page-title {
  font-weight: bold;
}