// pallete
$primary: #1C51E5;
$dark-primary: #03178B;
$accent: #E0E7F9;
$white: #FFFFFF;
$error: #DE2C2C;
$gray-1: #6B7798;
$gray-2: #D2D2D2;

// text
$txt-black: #000000;
$txt-white: #FFFFFF;
$txt-gray-1: #6B7798;
$txt-gray-2: #262626;
$txt-primary: #1C51E5;
$txt-dark-primary: #03178B;
$txt-accent: #E0E7F9;
$txt-error: #DE2C2C;

// calculator
$water-color: #29ABE2;

:root {
  --primary: #1C51E5;
  --dark-primary: #03178B;
  --accent: #E0E7F9;
  --error: #DE2C2C;
  --white: #FFFFFF;
  --gray-1: #6B7798;
  --gray-2: #D2D2D2;

  --txt-black: #000000;
  --txt-white: #FFFFFF;
  --txt-gray-1: #6B7798;
  --txt-gray-2: #262626;
  --txt-primary: #1C51E5;
  --txt-dark-primary: #03178B;
  --txt-accent: #E0E7F9;
  --txt-error: #DE2C2C;

  --water-color: #29ABE2;
}