@import "./variables.scss";

.txt-primary {
  color: $txt-primary;
}

.txt-dark-primary {
  color: $txt-dark-primary;
}

.txt-accent {
  color: $txt-accent;
}

.txt-gray-1 {
  color: $txt-gray-1;
}

.txt-white {
  color: $txt-white;
}

.txt-black {
  color: $txt-black;
}
