.divider {
  width: auto;
  height: 1px;

  &.vertical {
    width: 1px;
    height: auto;
  }
}

.spacer {
  flex-grow: 1;
}

.clickable {
  cursor: pointer;
}