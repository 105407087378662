// Estilização para a classe "row"
.row {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -15px;
}

// Estilização para as variações de "col"
@for $i from 1 through 12 {
  .col-#{$i} {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 #{percentage(calc($i / 12))};
    max-width: #{percentage(calc($i / 12))};
    box-sizing: border-box;
  }

  // Responsividade para larguras menores
  @media (min-width: 576px) {
    .row .col-sm-#{$i},
    .col-sm-#{$i} {
      flex: 0 0 #{percentage(calc($i / 12))};
      max-width: #{percentage(calc($i / 12))};
    }
  }

  @media (min-width: 768px) {
    .row .col-md-#{$i},
    .col-md-#{$i} {
      flex: 0 0 #{percentage(calc($i / 12))};
      max-width: #{percentage(calc($i / 12))};
    }
  }

  @media (min-width: 992px) {
    .row .col-lg-#{$i},
    .col-lg-#{$i} {
      flex: 0 0 #{percentage(calc($i / 12))};
      max-width: #{percentage(calc($i / 12))};
    }
  }

  @media (min-width: 1200px) {
    .row .col-xl-#{$i},
    .col-xl-#{$i} {
      flex: 0 0 #{percentage(calc($i / 12))};
      max-width: #{percentage(calc($i / 12))};
    }
  }
}
