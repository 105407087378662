@import "./variables.scss";

.bg-primary {
  background-color: $primary !important;
}

.bg-dark-primary {
  background-color: $dark-primary;
}

.bg-accent {
  background-color: $accent;
}

.bg-error {
  background-color: $error;
}

.bg-gray-1 {
  background-color: $gray-1;
}

.bg-gray-2 {
  background-color: $gray-2;
}

.bg-white {
  background-color: $white;
}

.bg-water-color {
  background-color: $water-color;
}