@import "./Assets/styles/container.scss";
@import "./Assets/styles/background.scss";
@import "./Assets/styles/text.scss";
@import "./Assets/styles/headings.scss";
@import "./Assets/styles/gridLayout.scss";
@import "./Assets/styles/utils.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  outline: none;
  border: none;
  background-color: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

html,
body,
#root,
.App {
  height: 100vh;
  scroll-behavior: smooth;
  font-family: 'Roboto';
}
